<template>
  <div class="production-calendar-footer box flex gap-m">
    <p class="production-calendar-footer__description">Производственный календарь составлен на основе Постановления Правительства РФ</p>

    <div class="column gap-xs">
      <span class="production-calendar-footer__label">Кол-во дней:</span>

      <div class="production-calendar-footer__list flex">
        <InfoText label="Календарных" :text="fullDays" />

        <InfoText label="Рабочих" :text="workDays" />

        <InfoText label="Вых./праздн." :text="weekendDays" />
      </div>
    </div>
  </div>
</template>

<script>
import InfoText from '@/components/simple/text/InfoText.vue'

export default {
  name: 'ProductionCalendarFooter',
  components: { InfoText },
  props: {
    year: {
      type: Number,
      required: true
    }
  },
  computed: {
    workDays () {
      return this.fullDays - this.weekendDays
    },
    weekendDays () {
      return this.$store.getters['calendar/weekendsCount']
    },
    fullDays () {
      return this.isLeapYear(this.year) ? 366 : 365
    },
    isLeapYear () {
      return (year) => {
        return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)
      }
    }
  }
}
</script>

<style lang="scss">
@import "styles";
</style>
