export const EDayTypes = {
  work: 'Рабочий',
  weekend: 'Выходной'
}

export const dayTypeList = [EDayTypes.weekend, EDayTypes.work].map(mapDayTypes)

function mapDayTypes (value, i) {
  return {
    value,
    id: i + 1,
    title: value
  }
}
