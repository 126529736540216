<template>
  <div
    class="production-calendar"
    :class="isDisabled && 'disabled'"
    @mouseleave="closeModalDayTypes"
  >
    <FullCalendar ref="calendar" :options="calendarOptions" />

    <transition name="fade">
      <ModalDayTypes
        v-if="isModal"
        :class="modal.isRightEdge && 'is-right-edge'"
        :position="modal.position"
        :day-type="modal.dayType"
        @change-day-type="changeDayType"
        @mouseleave.native="closeModalDayTypes"
      />
    </transition>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import multiMonthPlugin from '@fullcalendar/multimonth'
import interactionPlugin from '@fullcalendar/interaction'
import ModalDayTypes from './modal/ModalDayTypes.vue'
import { mixinModal } from '@/mixins/modal/mixinModal'
import { mapMutations, mapState } from 'vuex'
import { EDayTypes } from '@/components/views/LoadCalendar/ProductionCalendar/model'

const MODAL_WIDTH = 160
const MAIN_PADDING_RIGHT = 30

export default {
  name: 'ProductionCalendar',
  components: {
    ModalDayTypes,
    FullCalendar
  },
  mixins: [mixinModal],

  props: {
    year: {
      type: Number,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
      calendarOptions: {
        plugins: [multiMonthPlugin, interactionPlugin],
        initialDate: this.currentDate,
        timeZone: 'local',
        locale: 'ru',
        firstDay: 1,
        initialView: 'multiMonthYear',
        multiMonthMaxColumns: 4,
        headerToolbar: {
          left: '',
          center: 'title',
          right: ''
        },
        dateClick: this.onDate
      },

      modal: {
        isModal: false,
        isRightEdge: false,
        position: {
          x: 0,
          y: 0
        },
        dayType: '',
        currentEl: null,
        currentDate: ''
      }
    }
  },

  computed: {
    ...mapState('calendar', {
      currentDate: 'currentDate',
      weekends: 'weekends'
    }),

    calendarApi () {
      return () => this.$refs.calendar?.getApi()
    },

    getCoords () {
      const mainEl = document.querySelector('main')

      return (el) => {
        const { top, left, width, height } = el.getBoundingClientRect()

        return {
          top: top + mainEl.scrollTop - 60, // 60 - header height
          left,
          width,
          height,
          mainElWidth: mainEl.offsetWidth
        }
      }
    }
  },

  watch: {
    year (year) {
      this.goTo(`${year}-01-01`)
    }
  },

  mounted () {
  },

  updated () {
  },

  methods: {
    ...mapMutations('calendar', {
      ADD_WEEKEND: 'ADD_WEEKEND',
      REMOVE_WEEKEND: 'REMOVE_WEEKEND'
    }),

    goTo (date) {
      this.calendarApi().gotoDate(date)
    },

    reRenderWeekends () {
      this.clearRenderWeekends()
      this.renderWeekends()
    },

    clearRenderWeekends () {
      const monthWrap = this.$refs.calendar.$el.querySelector('.fc-multiMonthYear-view')

      const tdList = monthWrap.querySelectorAll('.is-weekend')
      tdList.forEach(el => this.removeClassIsWeekend(el))
    },

    renderWeekends () {
      const monthWrap = this.$refs.calendar.$el.querySelector('.fc-multiMonthYear-view')

      for (const date of this.weekends) {
        const td = monthWrap.querySelector(`[data-date="${date}"]`)
        this.addClassIsWeekend(td)
      }
    },

    onDate (dateEvent) {
      this.setModalCurrentDate(dateEvent.dateStr)
      const el = dateEvent.dayEl

      if (this.modal.currentEl) {
        this.removeClassIsActive(this.modal.currentEl)
      }

      this.addClassIsActive(el)
      this.setModalCurrentEl(el)

      const isWeekend = el.classList.contains('is-weekend')

      this.setModalDayType(isWeekend)

      this.setModalPosition(dateEvent)
      this.openModal()
    },

    changeDayType (type) {
      const isWeekend = type === EDayTypes.weekend
      const { currentEl } = this.modal

      isWeekend ? this.addClassIsWeekend(currentEl) : this.removeClassIsWeekend(currentEl)

      this.setWeekends(isWeekend)
    },

    setWeekends (isWeekend) {
      if (isWeekend) {
        this.ADD_WEEKEND(this.modal.currentDate)
      } else {
        this.REMOVE_WEEKEND(this.modal.currentDate)
      }
    },

    closeModalDayTypes () {
      if (!this.isModal) return

      this.closeModal()
      this.removeClassIsActive(this.modal.currentEl)
      this.resetModal()
    },

    setModalCurrentEl (el) {
      this.modal.currentEl = el
    },
    setModalCurrentDate (date) {
      this.modal.currentDate = date
    },
    setModalDayType (isWeekend) {
      this.modal.dayType = isWeekend ? EDayTypes.weekend : EDayTypes.work
    },
    setModalPosition (dateEvent) {
      const { top, left, width, height, mainElWidth } = this.getCoords(dateEvent.dayEl)

      const isRightEdge = (left + MODAL_WIDTH) > (mainElWidth - MAIN_PADDING_RIGHT)
      this.modal.isRightEdge = isRightEdge

      const x = isRightEdge ? (left - MODAL_WIDTH + width) : left + 1

      this.modal.position.x = x + 1
      this.modal.position.y = top + height + 8
    },

    resetModal () {
      this.modal.isRightEdge = false
      this.modal.position.x = 0
      this.modal.position.y = 0
      this.modal.dayType = ''
      this.modal.currentEl = null
      this.modal.currentDate = ''
    },

    addClassIsActive (el) {
      el.classList.add('is-active')
    },
    removeClassIsActive (el) {
      el.classList.remove('is-active')
    },

    addClassIsWeekend (el) {
      el.classList.add('is-weekend')
    },
    removeClassIsWeekend (el) {
      el.classList.remove('is-weekend')
    }
  }
}
</script>

<style lang="scss">
@import "styles";
</style>
