<template>
  <div class="modal-day-types flex" :style="styles">
    <VForm v-model="formData">
      <RadioGroup name="weekend" :options="dayTypeList" @change="onChange" />
    </VForm>
  </div>
</template>

<script>
import VForm from '@/components/Form/VForm.vue'
import RadioGroup from '@/components/Form/Radio/RadioGroup.vue'
import { dayTypeList } from '../model'

export default {
  name: 'ModalDayTypes',
  components: { VForm, RadioGroup },
  props: {
    dayType: {
      type: String,
      required: true
    },
    position: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    formData: {
      weekend: null
    },
    dayTypeList
  }),
  computed: {
    styles () {
      return {
        top: this.position.y + 'px',
        left: this.position.x + 'px'
      }
    }
  },
  watch: {
    dayType: {
      handler () {
        this.formData.weekend = this.dayType
      },
      immediate: true
    }
  },
  // created () {
  //   this.formData.weekend = this.dayType
  // }
  methods: {
    onChange (value) {
      this.$emit('change-day-type', value)
    }
  }
}
</script>

<style lang="scss">
@import "styles";
</style>
